import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import {
    deleteCustomerFailure,
    fetchOrganizationFailure,
    fetchOrganizationSuccess,
    getAllUsersAction,
    getCustomersById,
    getCustomersByIdFailure,
    getCustomersByIdSuccess,
    getOrganizationByIdSuccess,
    saveOrganizationFailure,
    saveOrganizationSuccess,
    setToastAction,
} from 'redux/actions';
import { OrganizationModel } from 'shared/model/OrganizationModel';
import { Effect } from '@redux-saga/types';
import {
    addOrganization,
    deleteOrganization,
    getOrganizations,
    searchOrganization,
    updateOrganization,
    getOrganizationByIdAPI,
    getCustomersAPI,
    deleteCustomerAPI,
} from 'api/orgAPI';
import { ToastVariant } from 'shared/utils/Constants';
import { t } from 'i18next';
import { UsersModel } from 'shared/model';
import store from 'redux/store/configStore';

/*
  Worker Saga: Fired on FETCH_ORGANIZATION_REQUEST action
*/
function* fetchOrganizationSaga(action: Effect) {
    yield afterFetchOrgDetails(getOrganizations, action.payload.org_type);
}

/*
  Worker Saga: Fired on FETCH_ORG_BY_NAME action
*/
function* fetchOrganizationSagaByName(action: Effect) {
    const body = {
        name: action.payload.name,
        additional_info: action.payload.additional_info,
        org_type: action?.payload?.org_type,
    };
    if (!action?.payload?.org_type) {
        delete body.org_type;
    }
    try {
        const response: OrganizationModel[] = yield call(searchOrganization, body);
        yield put(
            fetchOrganizationSuccess({
                organizations: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchOrganizationFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on FETCH_ORGANIZATION_BY_ID_REQUEST action
*/
function* fetchOrganizationSagaById(action: Effect) {
    try {
        const response: OrganizationModel = yield call(getOrganizationByIdAPI, action.payload.id);
        yield put(
            getOrganizationByIdSuccess({
                org: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchOrganizationFailure({
                error: e.message,
            }),
        );
    }
}

function* afterFetchOrgDetails(fn: any, payload?: any) {
    try {
        const response: { resp: OrganizationModel[] } = yield call(fn, payload);
        yield put(
            fetchOrganizationSuccess({
                organizations: response.resp,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchOrganizationFailure({
                error: e.message,
            }),
        );
    }
}

function* getCustomersSaga(action: Effect) {
    try {
        const response: UsersModel[] = yield call(getCustomersAPI, action.payload.org_id);
        yield put(getCustomersByIdSuccess(response));
    } catch (e: any) {
        yield put(
            getCustomersByIdFailure({
                error: e.message,
            }),
        );
    }
}

function* deleteCustomersSaga(action: Effect) {
    try {
        yield call(deleteCustomerAPI, action.payload.email);
        store.dispatch(getCustomersById({ org_id: action.payload.orgId }));
    } catch (e: any) {
        yield put(
            deleteCustomerFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on SAVE_ORGANIZATION_REQUEST action
*/
function* saveOrganizationSaga(action: Effect) {
    yield afterFetchDetails(addOrganization, action.payload);
}

/*
  Worker Saga: Fired on UPDATE_ORGANIZATION_REQUEST action
*/
function* updateOrganizationSaga(action: Effect) {
    yield afterFetchDetails(updateOrganization, action.payload);
    yield put(getAllUsersAction());
}

/*
  Worker Saga: Fired on DELETE_ORGANIZATION_REQUEST action
*/
function* deleteOrganizationSaga(action: Effect) {
    yield afterFetchDetails(deleteOrganization, action.payload.id);
}

function* afterFetchDetails(fn: any, payload: any) {
    try {
        const reseponse: string = yield call(fn, payload);
        yield put(saveOrganizationSuccess());
        yield put(setToastAction(ToastVariant.SUCCESS, reseponse ?? t('success')));
    } catch (e: any) {
        yield put(
            saveOrganizationFailure({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Starts worker saga on latest dispatched `FETCH_ORGANIZATION_REQUEST`  or `SAVE_ORGANIZATION_REQUEST` action.
*/
function* organizationSaga() {
    yield all([
        takeLatest(actionTypes.FETCH_ORG_REQUEST, fetchOrganizationSaga),
        takeLatest(actionTypes.SAVE_ORGANIZATION_REQUEST, saveOrganizationSaga),
        takeLatest(actionTypes.UPDATE_ORGANIZATION_REQUEST, updateOrganizationSaga),
        takeLatest(actionTypes.DELETE_ORGANIZATION_REQUEST, deleteOrganizationSaga),
        takeLatest(actionTypes.FETCH_ORG_BY_NAME, fetchOrganizationSagaByName),
        takeLatest(actionTypes.FETCH_ORGANIZATION_BY_ID_REQUEST, fetchOrganizationSagaById),
        takeLatest(actionTypes.FETCH_CUSTOMERS_BY_ID, getCustomersSaga),
        takeLatest(actionTypes.DELETE_CUSTOMER_REQUEST, deleteCustomersSaga),
    ]);
}

export default organizationSaga;
