export const BASE_URL = process.env.REACT_APP_SERVER;
export const LOGIN_URL = 'login';
export const USER_URL = 'user';
export const USERS_URL = 'users';
export const INIVTE = 'invite';
export const INVITE_USER_URL = USER_URL + '/' + INIVTE;
export const VALIDATE = 'validate';
export const VALIDATE_INVITE_USER_URL = INVITE_USER_URL + '/' + VALIDATE;
export const ACTIVATE = 'activate';
export const ACTIVATE_INVITE_USER_URL = USER_URL + '/' + ACTIVATE;
export const REFRESH_URL = 'refresh';
export const RESEND = 'resend';
export const RESEND_INVITE_URL = INVITE_USER_URL + '/' + RESEND;
export const FORGOT_PASSWORD_URL = 'forgot-password';
export const RESET_PASSWORD_URL = 'reset-password';
export const DEVICE = 'device';
export const COMMANDS = '/commands';
export const CONFIRMATION = '/confirmation';
export const OPERATIONS = 'operations';
export const FILTER_VALUE = 'filter-value';
export const DASHBOARD_API = 'dashboard';
export const ORGANIZATION = 'organization';
export const SITE = 'site';
export const HEIRARCHY = 'hierarchy';
export const RHS = 'rhs';
export const GRAPH = 'graph';
export const SIE_RHS = SITE + '/' + RHS;
export const SIE_RHS_GRAPH = SITE + '/' + RHS + '/' + GRAPH;
export const ROLES = 'roles';
export const PREFRENCE = 'preference';
export const USER_ROLES_URL = USER_URL + '/' + ROLES;
export const USER_PREFERENCE = USER_URL + '/' + PREFRENCE;
export const MODEL = 'models';
export const FIRMWARE = 'firmware';
export const UPLOAD = 'upload';
export const LATEST_VERSION = 'latest-version';
export const DEVICES = 'devices';
export const ALLOCATE = 'allocate';
export const UNALLOCATE = 'unallocate';
export const OU = 'ou';
export const NON_OU = 'non-ou';
export const INSTALL = 'install';
export const UNINSTALL = 'uninstall';
export const USERS = 'users';
export const USER = 'user';
export const DELETE = 'delete';
